@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Inter:wght@400;500;600&display=swap");

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(44, 62, 80, 0.7);
    transition: background-color 0.3s ease;
    z-index: 1000;
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
}

.navbar.scrolled {
    background-color: rgba(26, 37, 47, 0.9);
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    max-width: 75rem;
    margin: 0 auto;
}

.navbar-logo {
    width: 10rem;
    display: grid;
    justify-content: center;
}

.navbar-menu {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.navbar-item {
    font-family: "Playfair Display", serif;
    font-size: 1rem;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.navbar-item:hover {
    color: #ffd700;
}

.navbar-button {
    font-family: "Inter", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    color: #ffffff;
    text-decoration: none;
    background-image: linear-gradient(90deg, #28a745, #ffd700);
    padding: 0.625rem 1.25rem;
    border-radius: 0.5rem;
    transition: background-image 0.3s ease, box-shadow 0.3s ease;
    border: none;
    cursor: pointer;
}

.navbar-button:hover {
    background-image: linear-gradient(to bottom left, #4ade80, #3b82f6);
}

.navbar-button:focus {
    outline: none;
    box-shadow: 0 0 0 0.188rem rgba(74, 222, 128, 0.5);
}

.navbar-button.mobile {
    display: none;
}

.navbar-burger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}

.navbar-burger span {
    width: 2rem;
    height: 0.25rem;
    background: #ffffff;
    border-radius: 0.625rem;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 0.063rem;
}

/* Placeholder for download button to maintain layout */
.navbar-button-placeholder {
    width: 10.75rem; /* Approximate width of the original button */
}

@media (max-width: 48rem) {
    .navbar-menu {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(44, 62, 80, 0.9);
        flex-direction: column;
        padding-top: 5rem;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
    }

    .navbar-menu.active {
        transform: translateX(0);
    }

    .navbar-item {
        font-size: 1.5rem;
        padding: 1rem 2rem;
    }

    .navbar-burger {
        display: flex;
    }

    .navbar-button.desktop {
        display: none;
    }

    .navbar-button.mobile {
        display: inline-block;
        margin-top: 1rem;
    }

    .navbar-button-placeholder {
        display: none;
    }
}
