.footer {
    background-color: #1a1a1a;
    color: #ffffff;
    padding: 2rem 0 1rem;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 75rem;
    margin: 1rem auto;
    padding: 1rem 1rem;
}

.footer h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.social-media-footer,
.cta-section,
.address-section {
    margin-bottom: 2rem;
}

.social-icons {
    display: flex;
    gap: 1rem;
}

.social-media-footer h3 {
    color: #ffffff;
}

.icon-button {
    background-color: #333;
    color: #fff;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.3s ease;
}

.email-address {
    color: #ffffff;
    text-decoration: none;
}

.email-address:hover{
    text-decoration: underline;
}

.icon-button:hover {
    transform: translateY(-0.313rem);
}

.facebook:hover {
    background-color: #3b5998;
}
.x-twitter:hover {
    background-color: #000000;
}
.instagram:hover {
    background-color: #e1306c;
}
.tiktok:hover {
    background-color: #FE2C55;
}

.cta-button {
    background: linear-gradient(90deg, #28a745, #ffd700);
    color: #ffffff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 1.563rem;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
}

.cta-button:hover {
    transform: scale(1.05);
    box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.1);
    background: linear-gradient(90deg, #218838, #ffc107);
}

address {
    font-style: normal;
    line-height: 1.6;
}

.footer-bottom {
    text-align: center;
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 0.063rem solid #333;
}

.footer-bottom p {
    font-size: 0.8rem;
}

@media (max-width: 48rem) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .social-icons {
        justify-content: center;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.cta-button {
    animation: pulse 2s infinite;
}
