*,
*::before,
*::after {
    box-sizing: border-box;
}

.about-us {
    font-family: "Playfair Display", serif;
    background-color: #ffffff;
    color: #2c3e50;
    padding: 2rem;
    max-width: 75rem;
    margin: 0 auto;
}

.header-image {
    width: 100%;
    height: 18.75rem;
    background-image: url("../../../Assets/Images/aboutusbanner.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    margin: 3.5rem 0 2rem 0;
}

h1 {
    text-align: center;
    margin: 2rem 0;
    font-size: 2.5rem;
}

.intro-text {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 3rem;
    text-align: justify;
    color: #000000;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
}

.header-image::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

h2 {
    color: #2c3e50;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.intro-text {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 3rem;
    text-align: justify;
    color: #000000;
}

.grid-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    max-width: 60rem;
    margin: 0 auto;
}

.grid-row {
    display: flex;
    gap: 3rem;
    align-items: center;
}

.grid-column {
    flex: 1;
}

.grid-column p {
    text-align: justify;
    color: #000000;
}

.grid-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.3);
}

.action-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
    align-self: flex-start;
}

.action-button.primary {
    background-color: #ffd700;
    color: #2c3e50;
}

.action-button.primary:hover {
    background-color: #ffc107;
    color: #ffffff;
    border: 0.063rem solid #2c3e50;
}

.action-button.secondary {
    background-color: #28a745;
    color: #ffffff;
}

.action-button.secondary:hover {
    background-color: #218838;
    color: #ffffff;
    border: 0.063rem solid #ffc107;
}

.leadership-team {
    margin-top: 4rem;
}

.section-title {
    font-size: 2.5rem;
    text-align: center;
    color: #2c3e50;
    margin-bottom: 2rem;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
}

.team-member {
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
    height: 21.625rem !important;
}

.team-member img {
    width: 100%;
    height: auto !important;
    object-fit: cover;
    transition: opacity 0.3s ease;
}

.team-member-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    transition: opacity 0.3s ease;
}

.team-member h3 {
    font-size: 1.2rem;
    margin: 0;
}

.team-member p {
    font-size: 0.9rem;
    margin: 0.5rem 0 0;
    opacity: 0.8;
}

.team-member-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.team-member-hover p {
    color: white;
    font-size: 1.2rem;
    text-align: center;
    padding: 1rem;
}

.team-member:hover img {
    opacity: 0.3;
}

.team-member:hover .team-member-info {
    opacity: 0;
}

.team-member:hover .team-member-hover {
    opacity: 1;
}

.marketing-work {
    margin-top: 4rem;
}

.marketing-intro,
.marketing-conclusion {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #000000;
    text-align: justify;
    margin-bottom: 2rem;
}

.marketing-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 2rem;
}

.marketing-item {
    display: flex;
    flex-direction: column;
}

.marketing-image {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.3);
    margin-bottom: 1rem;
}

.marketing-item h3 {
    color: #2c3e50;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.marketing-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: #000000;
    text-align: justify;
}

@media (min-width: 75rem) {
    .team-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 48rem) {
    .about-us {
        padding: 1rem;
    }

    .grid-container {
        padding: 0 1rem;
    }

    .header-image {
        margin: 2rem 0;
    }

    .grid-row {
        flex-direction: column;
        margin: 0;
    }

    .grid-column {
        flex: 1;
        padding: 0;
    }

    .marketing-grid {
        grid-template-columns: 1fr;
    }

    .grid-row {
        flex-direction: column;
    }

    .grid-row:nth-child(1) .grid-column:nth-child(1) {
        order: 1;
    }

    .grid-row:nth-child(1) .grid-column:nth-child(2) {
        order: 2;
    }

    .grid-row:nth-child(2) .grid-column:nth-child(1) {
        order: 4;
    }

    .grid-row:nth-child(2) .grid-column:nth-child(2) {
        order: 3;
    }
}

@media (max-width: 30rem) {
    .team-grid {
        grid-template-columns: 1fr;
    }
}
