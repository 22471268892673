@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap");

.contact-container {
    font-family: "Playfair Display", serif;
    background-color: #ffffff;
    color: #2c3e50;
    min-height: 100vh;
}

.contact-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin: 5rem 0 1rem 0;
}

.cta-container {
    font-size: 1.25rem;
    color: #000000;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 600;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
}

.label-form {
    text-align: left;
}

.contact-content {
    max-width: 75rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

.contact-form-container {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
}

.contact-info-main {
    display: grid;
    justify-content: center;
    background-color: #f8f9fa;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
    padding: 2rem;
}


.contact-info-container {
    margin: auto;
}

.contact-info svg {
    color: #000000;
}

h2,
h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 0.5rem;
}

input,
textarea {
    padding: 0.5rem;
    border: 0.063rem solid #2c3e50;
    border-radius: 0.25rem;
    font-family: inherit;
}

.submit-button {
    background-color: #ffd700;
    color: #2c3e50;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.submit-button:hover {
    background-color: #ffc107;
    color: #ffffff;
}

.contact-info {
    display: flex;            
    flex-direction: column;    
    align-items: center;      
    justify-content: center;    
    text-align: center;        
    margin: 0;                
}

.contact-info p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0;
    color: #000000;
}

.social-media,
.cta-section,
.address-section {
    margin-bottom: 2rem;
}

.social-media h3 {
    color: #2c3e50;
}
.social-icons {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.icon-button {
    background-color: #333;
    color: #fff;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.3s ease;
}

.icon-button:hover {
    transform: translateY(-0.313rem);
}

.facebook:hover {
    background-color: #3b5998;
}
.x-twitter:hover {
    background-color: #000000;
}
.instagram:hover {
    background-color: #e1306c;
}
.tiktok:hover {
    background-color: #FE2C55;
}

.map-container {
    margin-top: 2rem;
}

.map-container h3 {
    color: #2c3e50;
}

.map-container iframe {
    border-radius: 0.5rem;
}

@media (min-width: 48rem) {
    .contact-container {
        padding: 1rem;
    }
    .contact-content {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 48rem) {
    .contact-info p {
        margin: 0 0 0.5rem 4rem;
    }
    .cta-container {
        font-size: 1rem;
        margin-bottom: 1.5rem;
        padding: 1rem;
    }
}
