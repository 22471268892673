.landing-page {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}

.carousel {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slide {
    position: relative;
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.slide::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3) 35%, rgba(0, 0, 0, 0.1) 65%, rgba(0, 0, 0, 0.4));
}

.slide1 {
    background-image: url("../../../Assets/Images/upcoming_events_01.png");
}
.slide2 {
    background-image: url("../../../Assets/Images/upcoming_events_06.png");
}
.slide3 {
    background-image: url("../../../Assets/Images/upcoming_events_07.png");
}

.slide-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index: 2;
    width: 90%;
    max-width: 800px;
}

.slide-content h2 {
    font-family: "Playfair Display", serif;
    font-size: 3rem;
    margin-bottom: 1rem;
    text-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
    color: white;
}

.slide-content p {
    font-size: 1.5rem;
    max-width: 37.5rem;
    margin: 0 auto;
    text-shadow: 0.063rem 0.063rem 0.125rem rgba(0, 0, 0, 0.5);
}

.slick-dots {
    bottom: 1.563rem;
}

.slick-dots li button:before {
    color: #fff;
    opacity: 0.5;
    font-size: 0.75rem;
}

.slick-dots li.slick-active button:before {
    color: #fff;
    opacity: 1;
}

.grid-section {
    padding: 4rem 2rem;
    background-color: #f8f8f8;
}

.grid-section h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
}

.landing-page-grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    max-width: 1200px;
    margin: 0 auto;
}

.grid-item {
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.grid-item:hover {
    transform: translateY(-0.313rem);
}

.grid-item img {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
}

.grid-item h3 {
    font-size: 1.25rem;
    margin: 1rem;
    color: #2c3e50;
}

.grid-item p {
    font-size: 0.9rem;
    margin: 0 1rem 1rem;
    color: #000000;
    text-shadow: none;
}

/* Tablet view */
@media (max-width: 1024px) {
    .slide {
        height: 60vh;
    }

    .slide-content h2 {
        font-size: 2.5rem;
    }

    .slide-content p {
        font-size: 1.25rem;
    }

    .landing-page-grid-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        gap: 1rem;
        padding-bottom: 1rem;
        max-width: 100%;
        margin: 0 auto;
    }

    .grid-item {
        flex: 0 0 auto;
        width: calc(33.333% - 0.667rem);
        min-width: 250px;
    }

    .grid-item:nth-child(4),
    .grid-item:nth-child(5) {
        display: block;
    }

    .grid-section {
        padding: 3rem 1.5rem;
    }

    .grid-section h2 {
        font-size: 2.25rem;
    }
}

/* Mobile view */
@media (max-width: 768px) {
    .slide {
        height: 50vh;
    }

    .slide-content h2 {
        font-size: 2rem;
    }

    .slide-content p {
        font-size: 1rem;
    }

    .landing-page-grid-container {
        flex-direction: column;
        overflow-x: visible;
    }

    .grid-item {
        width: 100%;
        display: flex !important;
        align-items: center;
    }

    .grid-item img {
        width: 40%;
        height: 100%;
        max-height: 10rem;
    }

    .grid-item-content {
        width: 60%;
        padding: 1rem;
    }

    .grid-item h3 {
        font-size: 1.1rem;
        margin: 0 0 0.5rem 0;
    }

    .grid-item p {
        font-size: 0.85rem;
        margin: 0;
    }

    .grid-section h2 {
        font-size: 2rem;
    }

    .grid-section {
        padding: 2rem 1rem;
    }
}

/* Small mobile view */
@media (max-width: 480px) {
    .slide {
        height: 40vh;
    }

    .slide-content h2 {
        font-size: 1.75rem;
    }

    .slide-content p {
        font-size: 0.9rem;
    }

    .grid-item {
        flex-direction: column;
    }

    .grid-item img {
        width: 100%;
        max-height: 12rem;
    }

    .grid-item-content {
        width: 100%;
    }
}
